import React from 'react';
import './App.css';

function App() {
    return (
        <div className="App">
            <div className="container">
                <div className="title">Jar Jar Bitch.</div>
                <div className="subtitle">Who will pay the breakfast?</div>
                <div className="counter">In this sprint, already <span className="nb">27</span> entries in the Jar Jar.
            </div>
            <div className="buttonBlock">
                <button className="btnNew">New entry</button>
                <button className="btnClose">Close sprint</button>
            </div>
            </div>
            <div className="footer">Made with <span className="heart">❤️</span>by exo'tic</div>
        </div>
    );
}

export default App;
